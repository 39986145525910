<template>
 <div id= "main">
<template v-if="cmp_clList.length">
</template>
<template v-else>
  <mt-spinner class="wait" type="fading-circle" color="#26a2ff" :size="60"></mt-spinner>
</template>
 </div>
</template>

<style lang="scss" scoped>

  #main {
    margin:10px;
    display:flex;
     display: -webkit-flex;
     justify-content:center;
					align-items: center;
					flex-direction: column;
    .lc {
			margin: 0 0 10px 0;
      width:80%;
      background-color:#26a2ff;
      color:white;
    };
  } 

</style>

<script>

  /*<mt-button v-for="item in cmp_clList" @click="gotolink(item.lc)" v-bind:key="item.lc" class='lc'>{{item.title}}</mt-button> */

export default{


computed:{
     cmp_clList:(vue)=>{
        return vue.$store.state.homeNavList
     }
},

methods:{
         gotolink(lc){
           this.$router.replace({path:"/scsj/" + lc})
         }
        }

}

</script>
