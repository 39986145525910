import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
   homeNavList:[],
			//lc:Vue.$route.params.lc
   lcLabel:{},
   itemLabel:{} ,
   isScsjNavFixed:false,
   itemLabelId:"",
   itemValue:[],
    updateTime:"",
  },



  mutations: {
    setHomeNavList(state,data){
      state.homeNavList = data
    },

    setLcLabel(state,data){
      console.log('lcLabel',data.value)
     Vue.set(state.lcLabel,data.lc,data.value)
    },

    setItemLabel(state,data){
    let i = 0
    for (let label of  data.label){
      for (let item of label.item){
          item.index = i 
          i++
      }
    }
    console.log('setItemLabel::',data)
    state.itemLabelId = data.fid
     Vue.set(state.itemLabel,data.fid,data.label)
      
    },
    
    setItemLabelId(state,data){
      state.itemLabelId = data
    },

    setItemValue(state,data){
      //console.log('setItemValue')
      state.itemValue = data
    let td = new Date();
    let Y = td.getFullYear();
    let M = td.getMonth() + 1 ;
    let D = td.getDate();
      if (M < 10){
          M = "0" + M
      }
      if (D < 10){
          D = "0" + D
      }
    //let h = td.getHours();
    //let m = td.getMinutes();
    //let s = td.getSeconds();
      //state.updateTime = Y + "-" + M + "-" + D + " " + h + ":" + m + ":" + s
      //state.updateTime = new Date().toTimeString().substr(0,8)
      state.updateTime = Y + "-" + M + "-" + D + " " + new Date().toTimeString().substr(0,8)
    },
  },



  actions: {
    getHomeNavList(store){
      if (store.state.homeNavList.length){return}
      axios.get('/api/label/lc').then(res=>{
       store.commit('setHomeNavList',res.data) 
      
      })
    },
    getLcLabel(store,lc){
      //debugger
      if (store.state.lcLabel[lc]){
        let data = store.state.lcLabel[lc]
        console.log('data',data)
        if (data.default){
          console.log('exit lc')
          //store.state.itemLabelId = data.default
          store.dispatch('getItemLabel',{lc:lc,fid:data.default})
        }
      }
      else{
      //console.log('getLcLabel===',lc)
      axios.get('/api/label/lcLabel/' + lc).then(res=>{
       let obj = {
        lc:lc,
        value:res.data
       }
       store.commit('setLcLabel',obj) 
        if (res.data.default){
          store.dispatch('getItemLabel',{lc:lc,fid:res.data.default})
        }
      })
      }
    },
  getItemLabel(store,lc_gr){
      
      store.state.itemValue = []
      if (!(store.state.itemLabel[lc_gr.fid])){ 
      axios.get('/api/label/itemLabel/' + lc_gr.lc + "/" + lc_gr.fid).then(res=>{
      console.log('itemLabel:',res.data)
      store.commit('setItemLabel',res.data) 
      })
      }
      else
      {
      store.commit('setItemLabelId',lc_gr.fid)  
      }
  },
  },

  modules: {
  },
})
