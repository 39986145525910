import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
//import scsj from '../views/scsj.vue'
import Home from '../views/Home.vue'
import store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/scsj/:lc',
    name: 'scsj',
    //component: scsj,
    //beforeEnter:function(to,from,next){
      //console.log('from',from)
      //console.log('to',to)
      //let exitlcLabel  = router.app.$store.state.lcLabel.hasOwnProperty(to.params.lc)
      //if (!lcLabel){
      //if (!exitlcLabel){
      //router.app.$store.dispatch('getLcLabel',to.params.lc)
      //}
      //next() 
    
    //}
    meta:{
      title:'实时数据'
    },
    component: () => import('../views/scsj.vue')
  },
 {
   path:'/',
   name:'Home', 
   meta:{
    title:'生产数据'
   },
   component:Home,
 }
  //{
    //path: '/about',
    //name: 'About',
    //// route level code-splitting
    //// this generates a separate chunk (about.[hash].js) for this route
    //// which is lazy-loaded when the route is visited.
    //component: () => import([> webpackChunkName: "about" <] '../views/About.vue')
  //}
]


const router = new VueRouter({
  //mode: 'history',
  //base: process.env.BASE_URL,
  //base:'scsj',
  routes
})

//router.beforeEach((to,from,next)=>{
  //if (to.path === from.path){
   //next(false) 
   //return
  //}
  //let arr = to.path.split('/')
  //if (arr[1]== 'scsj' && !arr[3]){
   //console.log('routes lc' , arr[2])
   //router.app.$store.dispatch('getLcLabel',arr[2])
    ////let a =  store.state.lcLable
    ////console.log(store.state.lcLable)
    ////console.log(a)
  //}
  ////console.log('path_split::',arr)
  ////console.log('to',to.path)
  ////console.log('from',from.path)
  ////next()

//})

export default router
