<template>
  <div>
    <mt-header :title="title">
      <mt-button icon="back" slot="left" @click="gotoOutLink({page:'home'})">主页</mt-button>
      <mt-button icon="more" slot="right" @click="sheetVisible=!sheetVisible">切换</mt-button>
    </mt-header>
    <mt-actionsheet
      v-model="sheetVisible"
      :to="{ path: '/scsj' }"
      :actions="homeNavList"
    ></mt-actionsheet>
  </div>
</template>

<style scoped lang="scss">

</style>

<script>
export default {
  name:"navbar_home",
  data(){
    return {
      sheetVisible:false,
      apple : false,

      homeNavList: [
        {
          name: "实时数据",
        },
        {
          name: "历史数据",
          page:'table',
          method: this.gotoOutLink
        }
      ],
    }
  },
  methods: {

    gotoOutLink(action) {
      let page = action.page
      let lc = this.$route.params.lc;
      if (!lc){return}
      //let domain_port = window.location.host;
      //let maohao = domain_port.indexOf(":");
      //let domain = "";
      //if (maohao > 0) {
      //  domain = domain_port.substr(0, maohao);
      //} else {
      //  domain = domain_port;
      //}
      let domain = document.domain.split('.').slice(-2).join('.')
      if (page == "home") {
        window.location.replace("https://www." + domain)
      } else if (page == "table") {
        window.location.replace("https://lssj." + domain + "/#/" + lc + "/table")
      }
    }
  },
computed:{
           title(){
             if (this.$route.params.lc == 'jbsc'){
                  return "江北水厂"
             }
             else if(this.$route.params.lc == 'thsc'){
                  return "潼湖水厂"
             }
             else if(this.$route.params.lc == 'qdsc'){
                  return "桥东水厂"
             }
             else if (this.$route.params.lc == 'hnasc'){
                  return "河南岸水厂"
             }
             return ""
           }

         }
}
</script>
