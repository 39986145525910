import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
//import VueWechatTitle from 'vue-wechat-title'

//mint-ui
import MintUI from 'mint-ui'

//import { Header } from 'mint-ui';
//Vue.component(Header.name,Header)
//import { Button } from 'mint-ui';
//Vue.component(Button.name,Button)
//import { Actionsheet } from 'mint-ui';
//Vue.component(Actionsheet.name,Actionsheet)
//import { Spinner } from 'mint-ui';
//Vue.component(Spinner.name, Spinner); 

//import { Tabbar, TabItem } from 'mint-ui';
//Vue.component(Tabbar.name, Tabbar);
//Vue.component(TabItem.name, TabItem);

import VueSocketIO from 'vue-socket.io';

//import socketio from 'socket.io-client';
Vue.use(new VueSocketIO({
    //debug: true,
  connection: '/scsj',
    //vuex: {
        //store,
        //actionPrefix: 'SOCKET_',
        //mutationPrefix: 'SOCKET_'
    //},
    //options: { path: "/scsj" } //Optional options
}))

axios.interceptors.response.use(function (response) {
  return response;
},function (error) {
  if (error.response.status === 401) {
		console.log("err401")
    window.location = error.response.data
}
}
)

Vue.prototype.axios = axios

//Vue.prototype.$homeNavList = axios
//.get('/api/label/lc')
//.then(res=>{
//console.log(res)
////Vue.prototype.$homeNavList = res.data
//return res.data
//})

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
