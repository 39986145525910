<template>
  <div id="app">
   <navbarHome></navbarHome>
   <router-view ></router-view>
  </div>
</template>

<style lang="scss">
html,body,#app{
  height: 100%;
  width: 100%;
	margin:0;
}
.wait{
   display:flex;
   justify-content:center;
}
.mint-header-title {
  white-space:pre;
}
</style>

<script>
import navbarHome  from './components/navbar_home.vue';
export default{


components:{
 navbarHome:navbarHome,

},

beforeCreate(){
    self.vue = this
    self.store = this.$store
    self.state = this.$store.state
   this.$store.dispatch('getHomeNavList')

},

}


</script>
